<template>
  <core-menu>
    <region-menu-header
      name="scenarios"
      icon="mdi-trending-up"
      :disable-region-menu="false"
      :disable-data-request="true"
    />
    <v-divider />
    <v-divider />
    <v-card>
      <v-tabs v-model="tab" fixed-tabs>
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab>
          {{ $t("coastMenu.scenarios") }}
        </v-tab>
        <v-tab-item class="pt-3">
          <span class="pl-5">{{ $t("stormWaveHeigth") }}</span>
          <div class="text-left pl-5 pt-2 pb-5">
            <v-chip
              @click="category = i"
              class="mx-2"
              v-for="i in windCategories"
              :key="i"
              :color="i == category ? 'grey' : 'light'"
            >
              {{ i }}yr
            </v-chip>
          </div>
          <span class="pl-5">{{ $t("windDirection") }}</span>
          <div class="text-left pl-5 pt-2 pb-5">
            <v-chip
              class="mx-1"
              v-for="i in arrowArray"
              :key="i"
              @click="direction = i"
              :color="i == direction ? 'grey' : 'light'"
            >
              {{ i }}°
            </v-chip>
          </div>
          <v-divider />
          <v-expansion-panels
            accordion
            multiple
            v-model="panel"
            v-if="mapLoaded && !loading"
          >
            <wave-parameters-entry
              :arrow-array="arrowArray"
              :direction="direction"
              :category="category"
              :raster-layer="filteredrasterLayer"
            ></wave-parameters-entry>
            <erosion-profiles-entry
              :arrow-array="arrowArray"
              :direction="direction"
              :category="category"
              :place="'forecast'"
              :raster-layer="filteredrasterLayer"
            ></erosion-profiles-entry>
          </v-expansion-panels>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </core-menu>
</template>

<script>
import RegionMenuHeader from "@/core/components/menu/RegionMenuHeader.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import CoreMenu from "@/core/components/menu/CoreMenu.vue";
import WaveParametersEntry from "@/core/components/menu/coastMenu/WaveModelParameterEntry.vue";
import ErosionProfilesEntry from "@/core/components/menu/coastMenu/ErosionProfilesEntry.vue";
import coastLayerMixin from "@/core/mixins/coastLayer.mixin";
import axios from "axios";

export default {
  name: "ForecastMenu",
  components: {
    ErosionProfilesEntry,
    WaveParametersEntry,
    CoreMenu,
    RegionMenuHeader
  },
  mixins: [coastLayerMixin],
  data: () => ({
    rasterLayer: [],
    filteredRasterLayer: [],
    category: 100,
    direction: 15,
    loading: true,
    panel: [0, 1],
    tab: null,
    virtualStations: [],
    windCategories: [100, 50, 20, 10, 1],
    arrowArray: [15, 45, 75, 105, 135, 165]
  }),
  computed: {
    ...mapState("app", ["visualizeMapLoaded"]),
    ...mapGetters("management", ["activeRegion"]),
    mapLoaded() {
      return this.visualizeMapLoaded;
    }
  },
  methods: {
    ...mapActions("management", ["getOrFetchAccessToken"]),
    filterRaster() {
      let filtered = this.rasterLayer.filter(
        layer => layer.product === "wvh" || layer.product === "esc"
      );

      if (!filtered?.length) {
        filtered = this.rasterLayer.filter(
          layer => layer.product === "wvh" || layer.product === "esc"
        );
      }
      return filtered;
    }
  },
  watch: {
    async activeRegion() {
      this.loading = true;
      this.rasterLayer = await this.fetchLayers();
      this.filteredrasterLayer = this.filterRaster();
      this.loading = false;
    },
    async category() {
      this.loading = true;
      this.rasterLayer = await this.fetchLayers();
      this.filteredrasterLayer = this.filterRaster();
      this.loading = false;
    },
    async direction() {
      this.loading = true;
      this.rasterLayer = await this.fetchLayers();
      // this.filteredrasterLayer = this.filterRaster();
      this.loading = false;
    }
  },
  created() {
    this.getOrFetchAccessToken().then(async () => {
      this.rasterLayer = await this.fetchLayers();
      this.filteredrasterLayer = this.filterRaster();
      if (!this.filteredrasterLayer.length) {
        this.$store.dispatch("raster/setActiveLayer", null);
      }
      this.loading = false;
      const response = await axios.get(
        `/userdata/virtual-stations/?region=${this.$route.params.regionId}`
      );
      this.virtualStations = response.data;
    });
  }
};
</script>

<style></style>
