<template>
  <div style="width: 100%" v-if="dataAvailable">
    <div
      v-for="(layer, index) in layerSelection"
      :key="layer.layerId"
      class="pb-5"
    >
      <v-card
        elevation="0"
        class="pt-5 pl-6"
        width="100%"
        @click="toggleLayer(index)"
      >
        <v-icon v-if="panel == index" class="pr-5">mdi-eye</v-icon>
        <v-icon v-else class="pr-5">mdi-eye-off</v-icon>
        {{ layer.title }}
      </v-card>
      <v-select
        v-if="filteredVirtualStations.length"
        :label="$t('coastMenu.selectedProfile')"
        :items="filteredVirtualStations"
        v-model="selectedProfile"
        return-object
        hide-details
        item-value="id"
        item-text="name"
        class="pl-12 pb-0 pt-7 pr-10"
        :disabled="panel == undefined"
        @change="zoomToVirtualStation(selectedProfile)"
      />
      <v-row class="pl-15 mt-5 mb-3">
        <v-card
          elevation="0"
          v-for="i in colormap"
          :key="i"
          style="border-radius: 0"
          :color="i"
          width="10px"
          class="py-5"
        ></v-card>
      </v-row>
      <v-row class="px-13 justify-center">
        <v-col class="pr-14">-150</v-col>
        <v-col style=" align-self: center" class="pr-16 pl-14">0</v-col>
        <v-col class="pl-16">150</v-col>
      </v-row>
      <v-row class="px-13 pt-4 justify-center"> m3/m</v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "axios";
import store from "@/core/store";
import bbox from "@turf/bbox";
import circle from "@turf/circle";
import coastLayerMixin from "@/core/mixins/coastLayer.mixin";
import VirtualStationMixin from "@/core/mixins/virtualStation.mixin";

export default {
  name: "ErosionProfilesEntry",
  mixins: [coastLayerMixin, VirtualStationMixin],
  data: () => ({
    selectedProfile: null,
    panel: 0,
    selectedStep: null,
    filteredVirtualStations: [],
    virtualStations: [],
    layerSelection: [],
    selectedLayer: null,
    datetimeSelection: [],
    dataAvailable: false
  }),
  props: {
    rasterLayer: Array,
    place: String,
    arrowArray: Array,
    direction: Number,
    category: Number
  },
  computed: {
    ...mapGetters("management", ["activeRegion"]),
    ...mapState("raster", ["activeRasterLayer"]),
    activeRegionId() {
      return this.$route.params.regionId;
    },
    colormap() {
      return [
        "#440000",
        "#550000",
        "#660000",
        "#770000",
        "#880000",
        "#990000",
        "#AA0000",
        "#CC0000",
        "#EE0000",
        "#FF3333",
        "#FF6666",
        "#FF9999",
        "#FFCCCC",
        "#FFE5E5",
        "#FFF5F5",
        "#FFFFFF",
        "#E8FFE8",
        "#E8FFE8",
        "#DAFFDA",
        "#CCFFCC",
        "#BEFFBE",
        "#B0FFB0",
        "#A2FFA2",
        "#86FF86",
        "#78FF78",
        "#3FFF3F",
        "#31E331",
        "#24B424",
        "#178517",
        "#0A570A",
        "#004000"
      ];
    }
  },
  methods: {
    ...mapActions("coastsPlot", [
      "setShowProfilePlot",
      "setProfile",
      "setProfileLayer",
      "setProfileTimesteps"
    ]),
    ...mapActions("plot", ["resetDataCache", "setStation"]),
    ...mapActions("raster", ["setLayerTimesteps"]),
    toggleLayer(index) {
      if (this.panel == index) {
        this.panel = undefined;
      } else {
        this.panel = index;
      }
    },
    filterRaster() {
      return this.rasterLayer.filter(layer => layer.product === "esc");
    },
    async fetchPermanentVirtualStations() {
      const response = await axios.get(
        `/userdata/virtual-stations/?region=${this.activeRegion.id}`
      );
      return response.data;
    },
    async getValue(feature) {
      const response = await this.$rastless.post(
        `/layers/${this.selectedLayer.layerId}/${this.selectedStep.datetime}/statistic?token=${this.accessToken}`,
        {
          statistic: "median",
          geometry: feature.geometry
        }
      );

      return Object.values(response.data.data)[0] / 10;
    },
    toggleLabels(map) {
      map.addLayer(
        {
          id: "profileLabels",
          type: "symbol",
          source: "profileSource",
          layout: {
            "text-field": ["get", "newName"],
            "text-variable-anchor": ["left"],
            "text-radial-offset": 0.5,
            "text-justify": "auto"
          }
        },
        "z-index-3"
      );
    },

    async addPermanentStationsToMap(stationArray) {
      let featureCollection = {
        type: "FeatureCollection",
        features: []
      };
      let array = [];
      let requests = [];
      stationArray.forEach((feature, index) => {
        requests.push(
          this.getValue(feature).then(async response => {
            array.push({ idx: index, data: response });
          })
        );
      });
      await Promise.all(requests);
      array.sort(function(a, b) {
        return a.idx - b.idx;
      });
      stationArray.forEach((feature, idx) => {
        let newFeature = {};
        newFeature.type = "Feature";
        newFeature.geometry = feature.geometry;
        newFeature.properties = feature;
        newFeature.properties.newName = feature.name.split("_")[1];
        newFeature.properties.value = array[idx].data;
        featureCollection.features.push(newFeature);
      });
      let map = this.$store.visualizeMap;
      if (map.getLayer("profileLayer")) {
        map.removeLayer("profileLayer");
      }
      if (map.getLayer("profileLabels")) {
        map.removeLayer("profileLabels");
      }
      if (map.getSource("profileSource")) {
        map.removeSource("profileSource");
      }
      map.addSource("profileSource", {
        type: "geojson",
        data: featureCollection
      });
      map.addLayer({
        id: "profileLayer",
        source: "profileSource",
        type: "circle",
        layout: {
          visibility: `visible`
        },
        paint: {
          "circle-color": {
            property: "value",
            stops: [
              [-15, "#440000"],
              [-14, "#550000"],
              [-13, "#660000"],
              [-12, "#770000"],
              [-11, "#880000"],
              [-10, "#990000"],
              [-9, "#AA0000"],
              [-8, "#CC0000"],
              [-7, "#EE0000"],
              [-6, "#FF3333"],
              [-5, "#FF6666"],
              [-4, "#FF9999"],
              [-3, "#FFCCCC"],
              [-2, "#FFE5E5"],
              [-1, "#FFF5F5"],
              [0, "#FFFFFF"],
              [1, "#E8FFE8"],
              [2, "#E8FFE8"],
              [3, "#DAFFDA"],
              [4, "#CCFFCC"],
              [5, "#BEFFBE"],
              [6, "#B0FFB0"],
              [7, "#A2FFA2"],
              [8, "#86FF86"],
              [9, "#78FF78"],
              [10, "#3FFF3F"],
              [11, "#31E331"],
              [12, "#24B424"],
              [13, "#178517"],
              [14, "#0A570A"],
              [15, "#004000"]
            ]
          },
          "circle-opacity": 1,
          "circle-radius": 9
        }
      });
      this.toggleLabels(map);
    },
    async fetchTimestepsProfile(layerId) {
      const response = await this.$rastless.get(
        `/layers/${layerId}/steps?token=${this.accessToken}`
      );
      const data = response.data;
      return data;
    },
    zoomToVirtualStation(item) {
      const mapItem = store.draw.get(item.id);
      const geojson = mapItem.geometry;

      let geojson_bbox = bbox(geojson);
      if (mapItem.geometry.type === "Point") {
        const myCircle = circle(geojson, 0.1, {
          steps: 10,
          units: "kilometers"
        });
        geojson_bbox = bbox(myCircle);
      }
      this.$store.visualizeMap.fitBounds(geojson_bbox, { padding: 100 });
    },
    filterVS() {
      return this.virtualStations.filter(station =>
        station.name.includes("Profile")
      );
    },
    async loadDate() {
      if (this.place == "visualize" && this.selectedLayer) {
        this.removeRasterLayer(this.selectedLayer.layerId);
      }
      if (this.panel === undefined) {
        this.$store.visualizeMap.U.hideSource([
          "mapbox-gl-draw-cold",
          "mapbox-gl-draw-hot",
          "profileSource"
        ]);
      } else {
        this.selectedLayer = this.layerSelection[this.panel];
        const datetimeSelection = await this.fetchTimestepsProfile(
          this.layerSelection[0].layerId
        );
        this.datetimeSelection = datetimeSelection
          .filter(step => step.datetime.includes(this.direction))
          .filter(step => step.datetime.includes(this.category));
        if (this.datetimeSelection.length) {
          this.dataAvailable = true;
          this.selectedStep = this.datetimeSelection[0];
          this.setProfileLayer({ ...this.selectedLayer, ...this.selectedStep });
          this.addPermanentStationsToMap(this.filteredVirtualStations);
          this.$store.visualizeMap.U.showSource([
            "mapbox-gl-draw-cold",
            "mapbox-gl-draw-hot",
            "profileSource"
          ]);
        } else {
          this.dataAvailable = false;
          this.$store.visualizeMap.U.hideSource([
            "mapbox-gl-draw-cold",
            "mapbox-gl-draw-hot",
            "profileSource"
          ]);
        }
        // this.addLayers(this.selectedLayer.layerId, this.selectedStep, this.accessToken, "visualize");
        // this.$store.dispatch("raster/setActiveLayer", { ...this.selectedStep, ...this.selectedLayer });
      }
    }
  },
  watch: {
    async panel() {
      await this.loadDate();
    }
  },
  async created() {
    if (this.$keycloak.authenticated) {
      this.layerSelection = this.filterRaster();
      if (this.layerSelection.length) {
        this.virtualStations = await this.fetchPermanentVirtualStations();
        this.filteredVirtualStations = this.filterVS();
        await this.loadDate();
      }
    }
  },
  beforeDestroy() {
    this.$store.visualizeMap.U.hideSource([
      "mapbox-gl-draw-cold",
      "mapbox-gl-draw-hot",
      "profileSource"
    ]);
  }
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
