<template>
  <div style="width: 100%" v-if="dataAvailable">
    <div v-for="(layer, index) in layerSelection" :key="layer.layerId">
      <v-card class="py-5 pl-6 mb-1" width="100%" @click="toggleLayer(index)">
        <v-icon v-if="panel == index" class="pr-5">mdi-eye</v-icon>
        <v-icon v-else class="pr-5">mdi-eye-off</v-icon>
        {{ layer.title }}
      </v-card>
    </div>
  </div>
</template>

<script>
import coastLayerMixin from "@/core/mixins/coastLayer.mixin";

export default {
  name: "WaveParametersEntry",
  mixins: [coastLayerMixin],
  data: () => ({
    panel: 0,
    selectedLayer: null,
    selectedStep: null,
    datetimeSelection: [],
    layerSelection: [],
    activeLayerSelection: [],
    dataAvailable: false
  }),
  props: {
    rasterLayer: Array,
    place: String,
    arrowArray: Array,
    direction: Number,
    category: Number
  },
  computed: {
    activeRegionId() {
      return this.$route.params.regionId;
    }
  },
  methods: {
    toggleLayer(index) {
      if (this.panel == index) {
        this.panel = undefined;
      } else {
        this.panel = index;
      }
    },
    filterRaster() {
      return this.rasterLayer.filter(
        layer => layer.product === "wvh" || layer.product === "wdi"
      );
    },
    async loadData() {
      if (this.panel !== undefined) {
        this.selectedLayer = this.layerSelection[this.panel];
        const datetimeSelection = await this.fetchTimesteps(
          this.selectedLayer.layerId
        );
        this.datetimeSelection = datetimeSelection
          .filter(step => step.datetime.includes(this.direction))
          .filter(step => step.datetime.includes(this.category));
        if (this.datetimeSelection.length) {
          this.dataAvailable = true;
          this.selectedStep = this.datetimeSelection[0];
          this.$store.visualizeMap.U.removeSource([
            "rasterSource",
            "rasterBackgroundSource",
            "vectorSource"
          ]);
          this.addLayers(
            this.selectedLayer.layerId,
            this.selectedStep,
            this.accessToken,
            "visualize"
          );
          this.$store.dispatch("raster/setActiveLayer", {
            ...this.selectedStep,
            ...this.selectedLayer
          });
        } else {
          this.dataAvailable = false;
          this.setLayerTimesteps([]);
          this.$store.dispatch("raster/setActiveLayer", {});
          this.datetimeSelection.forEach(step => {
            this.removeRasterLayer(
              this.selectedLayer.layerId,
              "raster",
              step.datetime
            );
          });
          if (this.selectedLayer) {
            this.removeRasterLayer(this.selectedLayer.layerId);
          }
        }
      } else {
        this.setLayerTimesteps([]);
        this.$store.dispatch("raster/setActiveLayer", {});
        this.datetimeSelection.forEach(step => {
          this.removeRasterLayer(
            this.selectedLayer.layerId,
            "raster",
            step.datetime
          );
        });
        if (this.selectedLayer) {
          this.removeRasterLayer(this.selectedLayer.layerId);
        }
      }
    }
  },
  watch: {
    async panel() {
      await this.loadData();
    }
  },
  async created() {
    this.layerSelection = this.filterRaster();
    this.loadData();
  }
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
